import * as React from 'react';
import { BodySecondary } from '@liveauctioneers/hammer-ui-core/text';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';

type Props = {
    children: React.ReactNode;
    className?: string;
};

const NoResults = ({ children, className = '' }: Props) => {
    return (
        <StyledNoResults className={className}>
            <BodySecondary color="secondary">{children}</BodySecondary>
        </StyledNoResults>
    );
};

export default React.memo<Props>(NoResults, isEqual);

const StyledNoResults = styled.div`
    width: 100%;
    min-height: 158px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.colors.blueLight};
    text-align: center;
    margin-left: 0;
    padding: 20px;
`;
