import { buildCanonicalUrl } from '@/utils/pagination';
import { cn } from '@liveauctioneers/hammer-ui-theme/cn';
import { defaultPageSizeOptions } from '@liveauctioneers/caterwaul-components/lib/Pagination/components/DefaultPageSizeOptions';
import { Pagination as HammerPagination, PaginationProvider } from '@liveauctioneers/hammer-ui-core/pagination';
import { Helmet } from 'react-helmet-async';
import { ModifyQueryParamsAction, useModifyQueryParams } from '@/hooks/queryParams/useModifyQueryParams';
import { OptionType, Select } from '@liveauctioneers/hammer-ui-core/select';
import { PaginationFilter } from './types/PaginationFilter';
import { useLocation } from 'react-router-dom';
import capitalize from 'lodash/capitalize';
import isEqual from 'react-fast-compare';
import React, { ReactNode, useCallback, useMemo } from 'react';

type PaginationProps = {
    /** Force set the canonical url, otherwise just whatever the page currently is */
    canonicalUrl?: string;
    /** Children (usually what is paginated through) */
    children?: any;
    /** To allow for custom styles and styled-components */
    className?: string;
    /** Node to be placed on top of the paginator above the content */
    filter?: ReactNode;
    /** Function that is run for state of page to be stored */
    onChange: Function;
    /** How many extra numbers should be displayed at the bottom (currentPage + pageRangeDisplayed) exluding first and last*/
    pageRangeDisplayed?: number;
    /** Possible page sizes */
    pageSizeOptions?: Array<number | 'all'>;
    /** Provides needed information */
    paginationFilter: PaginationFilter;
    /** Restricts the "final page" number to `# + restrictPagesTo`*/
    restrictPagesTo?: number;
    /** Should modify query params */
    shouldModifyQueryParams?: boolean;
    testid?: string;
    /** Override calculating page count by totalFound / pageSize  */
    totalPages?: number;
    /** Allows for custom label next to select of page sizes  */
    viewLabel?: ReactNode;
};

function Pagination({
    canonicalUrl,
    children,
    className,
    filter,
    onChange,
    pageSizeOptions = defaultPageSizeOptions,
    paginationFilter,
    // @ts-ignore not sure what default values should be
    restrictPagesTo,
    shouldModifyQueryParams,
    testid = 'pagination',
    totalPages = 0,
    viewLabel = 'View:',
}: PaginationProps) {
    const { page, pageSize, totalRecords = 0 } = paginationFilter;
    const modifyQueryParams = useModifyQueryParams();
    const { pathname } = useLocation();

    let normalizedCanonicalUrl: string = canonicalUrl || '';
    if (!canonicalUrl) {
        // if a canonicalUrl isn't provided, infer from window location, or do nothing during SSR
        normalizedCanonicalUrl = typeof window !== 'undefined' ? window?.location?.href : '';
    }

    const recordsPageCount = React.useMemo(() => {
        const calculatedPageSize = pageSize === 'all' ? 1 : Math.ceil(totalRecords / pageSize);
        if (restrictPagesTo) {
            const restrictedPageSize = page + restrictPagesTo;
            if (restrictedPageSize < calculatedPageSize) {
                return restrictedPageSize;
            } else {
                return calculatedPageSize;
            }
        } else {
            return calculatedPageSize;
        }
    }, [page, pageSize, restrictPagesTo, totalRecords]);

    const pageCount = totalPages || recordsPageCount;

    // If max page is restricted, we need to use the dynamic total records
    const dynamicTotalRecords = useMemo(() => {
        if (typeof pageSize === 'number' && pageCount * pageSize < totalRecords) {
            return pageCount * pageSize;
        }
        return totalRecords;
    }, [pageSize, pageCount, totalRecords]);

    let prevLink: ReactNode = '';
    if (page > 1) {
        const prevUrl = buildCanonicalUrl(normalizedCanonicalUrl, page - 1);
        prevLink = (
            <link
                href={prevUrl}
                rel="prev"
            />
        );
    }

    const currentLink = (
        <link
            href={buildCanonicalUrl(normalizedCanonicalUrl, page)}
            rel="canonical"
        />
    );

    let nextLink: ReactNode = '';
    if (pageCount !== page) {
        const nextUrl = buildCanonicalUrl(normalizedCanonicalUrl, page + 1);
        nextLink = (
            <link
                href={nextUrl}
                rel="next"
            />
        );
    }

    const handlePageChange = useCallback(
        (page: number) => {
            if (shouldModifyQueryParams) {
                if (page === 1) {
                    modifyQueryParams({
                        action: ModifyQueryParamsAction.REMOVE,
                        params: 'page',
                    });
                } else {
                    modifyQueryParams({
                        action: ModifyQueryParamsAction.ADD,
                        query: { page },
                    });
                }
            }

            onChange({ page });
        },
        [shouldModifyQueryParams, onChange, modifyQueryParams]
    );

    const handlePageSizeChange = useCallback(
        (value: OptionType) => {
            const { value: pageSize } = value ?? {};
            const pageSizeInt = pageSize ? parseInt(pageSize) : NaN;
            //We can't remove the page because redux won't have time to update and it will just get added back when we do a push. So just set it to 1 so it isn't inaccurate
            if (shouldModifyQueryParams) {
                modifyQueryParams({
                    action: ModifyQueryParamsAction.ADD,
                    query: { page: 1, pageSize: pageSizeInt },
                });
            }

            window.requestAnimationFrame(() => {
                onChange({ page: 1, pageSize });
            });
        },
        [shouldModifyQueryParams, onChange, modifyQueryParams]
    );

    return (
        <>
            {normalizedCanonicalUrl && (
                <Helmet>
                    {prevLink}
                    {currentLink}
                    {nextLink}
                </Helmet>
            )}
            <div>{filter}</div>
            <PaginationProvider
                initialPage={Number(page) || 1}
                initialPageSize={pageSize}
                itemCount={dynamicTotalRecords}
                key={`${page}-${pageSize}-${totalRecords}`}
                onPageChange={(state) => handlePageChange(state.page)}
                onPageSizeChange={(state) =>
                    handlePageSizeChange({ label: state.pageSize.toString(), value: state.pageSize })
                }
                pageSizeOptions={pageSizeOptions}
                path={pathname}
            >
                {children}
                <div
                    className={cn(
                        'mt-md grid grid-cols-[140px_1fr_140px] items-center smMax:mt-0 smMax:grid smMax:grid-cols-1 smMax:grid-rows-[min-content_min-content] smMax:py-xs',
                        className
                    )}
                    data-testid={testid}
                >
                    {/* Balance the grid */}
                    <div className="smMax:hidden" />
                    <div className="flex w-full justify-center smMax:row-start-2 smMax:row-end-3">
                        <HammerPagination />
                    </div>
                    <Select
                        className="min-w-[140px] py-md smMax:row-start-1 smMax:row-end-2"
                        data-testid="page-size-select"
                        instanceId="pagesize"
                        isInsideLabel
                        isSearchable={false}
                        labelText={viewLabel}
                        onChange={(value) => handlePageSizeChange(value)}
                        options={pageSizeOptions.map((pageSize) => ({
                            label: capitalize(String(pageSize)),
                            value: pageSize,
                        }))}
                        small
                        value={{
                            label: capitalize(String(pageSize)),
                            value: pageSize,
                        }}
                    />
                </div>
            </PaginationProvider>
        </>
    );
}

export default React.memo<PaginationProps>(Pagination, isEqual);
