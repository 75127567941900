import { UserBidPlacedItemsSortEnum, UserBidPlacedItemsStatusEnum } from '@/types/item/UserBidPlacedItem';

export const filterSortOptions = (sortOptions: string[], isBidderPage: boolean) => {
    if (isBidderPage) {
        sortOptions = sortOptions.filter((option) => {
            return option !== 'FEATURED_LOTS';
        });
        sortOptions = sortOptions.filter((option) => {
            return option !== 'TIME_ENDING_FURTHEST' && option !== 'TIME_SOONEST_FIRST';
        });
    }

    return sortOptions;
};

/**
 * The sort dropdown needs specific label text for newest/oldest depending on
 * which tab is selected (upcoming/ended), this function returns the correct intl id
 * to pass to formatMessage()
 */
export const getMyBidsSortOptionLabelText = (
    sortOption: keyof typeof UserBidPlacedItemsSortEnum,
    status: UserBidPlacedItemsStatusEnum
) => {
    if (sortOption === 'SALE_START_ASC' || sortOption === 'SALE_START_DESC') {
        return `paginateSortFilter.myBidsPagination.sortOptions.${status}.${UserBidPlacedItemsSortEnum[sortOption]}`;
    }

    return `paginateSortFilter.myBidsPagination.sortOptions.${UserBidPlacedItemsSortEnum[sortOption]}`;
};

export const PAGE_ITEMS_TO_VIEW = [24, 48, 72, 96, 120];
export const PAGE_ITEMS_TO_VIEW_PLUS_ALL: (number | 'all')[] = [24, 48, 72, 96, 120, 'all'];
